import React, { useState, useCallback, useEffect, useRef } from "react";
import { Title } from "../components/chrome";
// import * as d3 from "d3";

// import { useRecoilState } from "recoil";

// import { extent, max } from "d3-array";
// import { axisBottom, axisLeft } from "d3-axis";
// import { scaleLinear, scaleUtc } from "d3-scale";
// import { line } from "d3-shape";
// import { utcFormat } from "d3-time-format";

// import { newAtom } from "../atoms";
// import { Dropdown, timespans, updateOptions, api } from "../utils";
// import { useChartStuff } from "./company";
// import styled from "styled-components";

// export const draw = (data, elementRef, width) => {
//   const margin = { top: 20, right: 30, bottom: 30, left: 40 };

//   // const {width} = size;
//   const height = 300;

//   // Define the x-axis scale
//   const xScale = scaleUtc().range([margin.left, width - margin.right]);

//   // Define the y-axis scale
//   const yScale = scaleLinear().range([height - margin.bottom, margin.top]);

//   // Build the x-axis
//   const xAxis = (g) =>
//     g.attr("transform", `translate(0,${height - margin.bottom})`).call(
//       axisBottom(xScale)
//         // Format the date shown on the x-axis
//         .tickFormat(utcFormat("%H:%M"))
//       // %Y-%m-%dT%H:%M:%S
//     );

//   const yAxis = (g) =>
//     g.attr("transform", `translate(${margin.left},0)`).call(axisLeft(yScale));

//   const areaDef = d3
//     .area()
//     .x((d) => xScale(d.date))
//     .y0(height - margin.bottom)
//     .y1((d) => yScale(d.value));

//   const lineDef = line()
//     .defined((d) => !isNaN(d.value))
//     .x((d) => xScale(d.date))
//     .y((d) => yScale(d.value));

//   const svgEl = d3.select(elementRef.current);
//   svgEl.selectAll("*").remove();

//   xScale.domain(extent(data, (d) => d.date));
//   yScale.domain([0, max(data, (d) => d.value)]).nice();

//   const svg = svgEl
//     .append("g")
//     .attr("height", height)
//     .attr("viewBox", `0 0 ${width} ${height}`)
//     .attr("preserveAspectRatio", "xMinYMin meet");

//   svg
//     .append("path")
//     .datum(data)
//     .attr("class", "area")
//     .attr("d", areaDef)
//     .attr("fill", d3.schemePastel1[1]);

//   svg
//     .append("path")
//     .datum(data)
//     .attr("d", lineDef)
//     .attr("fill", "none")
//     .attr("stroke", d3.schemeSet1[1])
//     .attr("stroke-width", 1.5);

//   // let svgDefs = svg.append('defs');

//   // let filterBlur = svgDefs
//   //     .append("filter")
//   //     .attr('id', 'blur')
//   //     .attr("x", "0")
//   //     .attr("y", "0")
//   //     .append("feGaussianBlur")
//   //     .attr("in", "SourceGraphic")
//   //     .attr("stdDeviation", 20);

//   // let mask = svgDefs
//   //     .append("mask")
//   //     .attr('id', 'mask')
//   //     .datum(data)
//   //     .append("path")
//   //     .attr("fill", "#fff")
//   //     .attr("d", areaDef);

//   // svg.append("path")
//   //     .datum(data)
//   //     .attr("stroke", d3.schemePastel1[1])
//   //     .attr("stroke-width", 15)
//   //     .attr("fill", "none")
//   //     .attr("filter", "url(#blur)")
//   //     .attr("mask", "url(#mask)")
//   //     .attr("class", "line-blur")
//   //     .attr("d", lineDef);

//   svg.append("g").call(xAxis);
//   svg.append("g").call(yAxis);
// };

// let parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

// export const formatPoint = (i) => ({
//   date: parseTime(i.t),
//   value: i.v,
// });

// export const homeState = newAtom("homeState", {
//   timespan: "1h",
//   update: "off",
// });

const Home = () => {
  // const [form, setForm] = useRecoilState(homeState);
  // const [working, setWorking] = useState(false);

  // const [commentsWidth, commentsRef] = useChartStuff();
  // const [mentionsWidth, mentionsRef] = useChartStuff();

  // const [data, setData] = useState([]);

  // const updateData = useCallback(() => {
  //   setWorking(true);
  //   const { timespan } = form;
  //   const params = new URLSearchParams({ timespan });
  //   api
  //     .get(`/ingestors?${params}`)
  //     .then(({ data }) => {
  //       setData([
  //         data.comments.map(formatPoint),
  //         data.mentions.map(formatPoint),
  //       ]);
  //     })
  //     .catch(console.error)
  //     .finally(() => setWorking(false));
  // }, [form.timespan]);

  // useEffect(() => {
  //   const [comments, mentions] = data;
  //   if (!comments || !mentions) return;
  //   draw(comments, commentsRef, commentsWidth);
  //   draw(mentions, mentionsRef, mentionsWidth);
  // }, [data, commentsWidth, mentionsWidth]);

  // useEffect(updateData, [form]);
  // const timespanSelect = (value) => {
  //   setForm({ ...form, timespan: value });
  // };
  // const updateSelect = (value) => setForm({ ...form, update: value });

  // let interval;
  // useEffect(() => {
  //   clearInterval(interval);
  //   if (form.update > 0) {
  //     interval = setInterval(updateData, form.update * 1000);
  //   }
  //   return () => clearInterval(interval);
  // }, [form]);

  return (
    <div>

    </div>
  );

  // return (
  //   <div className="page">
  //     <div className="header">
  //       <h1 style={{ color: working ? "#AAA" : "#000" }}>Home</h1>

  //       <div className="controls">
  //         <div className="control timespan">
  //           <label>time span</label>
  //           <Dropdown
  //             options={timespans}
  //             onSelect={timespanSelect}
  //             defaultOption={form.timespan}
  //           />
  //         </div>
  //         <div className="control update">
  //           <label>auto update</label>
  //           <Dropdown
  //             options={updateOptions}
  //             onSelect={updateSelect}
  //             defaultOption={form.update}
  //           />
  //         </div>
  //       </div>
  //     </div>

  //     <div className="card">
  //       <h2>Ingested Comments</h2>
  //       <StyledSVG ref={commentsRef} height={300} />
  //     </div>

  //     <div className="card">
  //       <h2>Recorded Mentions</h2>
  //       <StyledSVG ref={mentionsRef} height={300} />
  //     </div>
  //   </div>
  // );
};

export default Home;

// const StyledSVG = styled.svg`
//   width: 100%;
// `;
