import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { Shell } from "./app/components/chrome";
import Home from "./app/pages/home";

import NewEvent from "./app/pages/newevent";
import Events from "./app/pages/events";
import Event from "./app/pages/event";

// import { Industries, Industry } from "./app/pages/industries";
// import { Company } from "./app/pages/companies";

createRoot(document.getElementById("root")).render(
  <RecoilRoot>
    <BrowserRouter>
      <Routes>
        <Route element={<Shell />}>
          <Route index element={<Home />} />
          <Route path="events">
            <Route index element={<Events />} />
            <Route path=":eventId" element={<Event />} />
            <Route path="new" element={<NewEvent />} />
          </Route>
          <Route path="auth">
            <Route path="login" element={<div>Login</div>} />
            <Route path="register" element={<div>Register</div>} />
            <Route path="verify" element={<div>Verify</div>} />
            <Route path="forgot" element={<div>Forgot</div>} />
            <Route path="reset" element={<div>Reset</div>} />
            <Route path="logout" element={<div>Logout</div>} />
          </Route>
          <Route path="account" element={<div>Account</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </RecoilRoot>
);
