import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  createContext,
  useContext,
} from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

import * as turf from "@turf/turf";

import {
  Map as MapGL,
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
// import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import { api } from "../api";
import { getAllPoints, getBoundingBox } from "../workbench/utils";

import {
  eventState,
  partiesState,
  scenariosState,
  routesState,
  filterState,
  mapState,
  loadingState,
  airlinesState,
  airportsState,
} from "../atoms";
import classNames from "classnames";


const INITIAL_VIEW_STATE = {
  latitude: 37.8,
  longitude: -96,
  zoom: 3,
};

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZHNpYml0emt5IiwiYSI6ImNseG1scG9ubjA3cmEybHB5dnNzYmx1OHUifQ.A8qPng0v9PR0QGjBbz3yWA";


const PlaceMarker = ({ item, selected, onClick }) => (
  <div
    className={classNames({
      [item.type]: true,
      marker: true,
      selected,
    })}
    style={{ backgroundColor: item?.color || null }}
    onClick={onClick}
  />
);

const AirportMarker = ({ airport }) => (
  <div className="airport marker">
    <h4>{airport.IATA}</h4>
  </div>
);


const LinePopup = ({ latitude, longitude, variants }) => {
  const [airlines, setAirlines] = useAtom(airlinesState);

  const stuff = variants.reduce((acc, v) => {
    // v.airline.id
    if (!acc[v.airline.id]) {
      acc[v.airline.id] = {
        ...v.airline,
        // count: 0,
        // passengers: 0,
      };
    }
    return acc;
  }, {});

  // console.log(stuff)

  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      closeButton={true}
      onClose={() => setPopups(null)}
    >
      {Object.values(stuff).map((airline) => (
        <div key={airline.id}>
          <h4>{airline.name}</h4>
        </div>
      ))}
    </Popup>
  );
};




const Map = () => {
  const [parties, setParties] = useAtom(partiesState);
  const [scenarios, setScenarios] = useAtom(scenariosState);
  const [airports, setAirports] = useAtom(airportsState);
  const [airlines, setAirlines] = useAtom(airlinesState);
  const [map, setMap] = useAtom(mapState);

  const routes = useAtomValue(routesState);

  const [mapStyle, setMapStyle] = useState(null);
  const [hovered, setHovered] = useState([]);
  const [selected, setSelected] = useState([]);
  const [popups, setPopups] = useState(null);

  const go = useCallback(
    (item) => {
      map.flyTo({
        center: item.point,
        zoom: 3,
        speed: 1.5,
      });
    },
    [map]
  );

  const itemMarker = (item, selected, setItems) => (
    <Marker
      key={`${item.type}-marker-${item.id}`}
      latitude={item.point[1]}
      longitude={item.point[0]}
    >
      <PlaceMarker
        item={item}
        selected={selected}
        onClick={(e) => {
          e.stopPropagation();
          setItems((prev) => ({
            ...prev,
            selected: selected ? null : item.id,
          }));
          if (!selected) go(item);
        }}
      />
    </Marker>
  );

  const pins = useMemo(
    () => [
      ...parties.items.map((p) =>
        itemMarker(p, parties.selected == p.id, setParties)
      ),
      ...scenarios.items.map((s) =>
        itemMarker(s, scenarios.selected == s.id, setScenarios)
      ),
    ],
    [parties, scenarios]
  );

  const airportPins = useMemo(
    () =>
      Object.values(routes).reduce((acc, party) => {
        for (const partyId in routes) {
          const party = parties.items.find((p) => p.id === parseInt(partyId));

          if (parties.selected && party.id !== parties.selected) continue;

          for (const scenarioId in routes[partyId]) {
            const scenario = scenarios.items.find(
              (s) => s.id === parseInt(scenarioId)
            );

            if (scenarios.selected && scenario.id !== scenarios.selected) continue;

            for (const [i, edges] of routes[partyId][scenarioId].entries()) {
              for (const edge of edges) {
                const airport = airports[edge.origin_id];

                if (!acc[airport.id]) {
                  acc[airport.id] = (
                    <Marker
                      key={`airport-marker-${airport.id}`}
                      latitude={airport.point[1]}
                      longitude={airport.point[0]}
                    >
                      <AirportMarker airport={airport} />
                    </Marker>
                  );
                }
              }
            }
          }
        }

        return acc;
      }, {}),
    [routes, parties, scenarios, airports]
  );

  useEffect(() => {
    if (!map || Object.keys(routes).length == 0) return;

    const features = [];
    const drawable_airports = [];

    // routes are organized by party and then scenario
    for (const partyId in routes) {
      const party = parties.items.find((p) => p.id === parseInt(partyId));

      if (parties.selected && party.id !== parties.selected) continue;

      // 
      for (const scenarioId in routes[partyId]) {
        const scenario = scenarios.items.find(
          (s) => s.id === parseInt(scenarioId)
        );

        if (scenarios.selected && scenario.id !== scenarios.selected) continue;

        for (const [i, edges] of routes[partyId][scenarioId].entries()) {

          for (const edge of edges) {
            const origin = airports[edge.origin_id];
            const destination = airports[edge.destination_id];

            if (!drawable_airports.includes(destination.id)) {
              drawable_airports.push(destination);
            }

            features.push({
              type: "Feature",
              properties: {
                key: `${edge.origin_id}-${edge.destination_id}`,
                variants: edge.variants,
              },
              geometry: {
                type: "LineString",
                coordinates: [origin.point, destination.point],
              },
            });
          }
        }
      }
    }

    map.addSource("routes", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: features,
      },
    });

    map.addLayer({
      id: "routes",
      source: "routes",
      type: "line",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#888",
        "line-width": 2,
      },
    });

    return () => {
      map.removeLayer("routes");
      map.removeSource("routes");
    };
  }, [map, routes, parties.selected, scenarios.selected]);

  useEffect(() => {
    if (!map) return;

    const onMouseMove = (e) => {
      if (!map.getLayer("routes")) return;

      const point = e.point;
      const width = 10;
      const height = 10;

      const incoming = map
        .queryRenderedFeatures(
          [
            [point.x - width / 2, point.y - height / 2],
            [point.x + width / 2, point.y + height / 2],
          ],
          { layers: ["routes"] }
        )
        .map((f) => f.properties.key)
        .filter((v, i, a) => a.indexOf(v) === i);

      if (incoming.join() !== hovered.join()) {
        setHovered(incoming);
      }
    };

    map.on("mousemove", onMouseMove);
    return () => map.off("mousemove", onMouseMove);
  }, [map, hovered]);

  useEffect(() => {
    if (!map || !hovered.length) return;

    const features = map.querySourceFeatures("routes", {
      filter: ["in", "key", ...hovered],
    });

    // draw new routes with a different color
    map.addSource("hovered", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: features,
      },
    });

    map.addLayer({
      id: "hovered",
      source: "hovered",
      type: "line",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#00F",
        "line-width": 2,
      },
    });

    return () => {
      map.removeLayer("hovered");
      map.removeSource("hovered");
    };
  }, [hovered]);

  const handleClick = useCallback(
    (e) => {
      setSelected(hovered);

      // const coordinates = e.lngLat;
      // setPopup({
      //   longitude: coordinates.lng,
      //   latitude: coordinates.lat,
      // });
    },
    [map, hovered]
  );

  // draw green routes for selected
  useEffect(() => {
    if (!map || !selected.length) return;

    // draw new routes with a different color
    map.addSource("selected", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: map.querySourceFeatures("routes", {
          filter: ["in", "key", ...selected],
        }),
      },
    });

    map.addLayer({
      id: "selected",
      source: "selected",
      type: "line",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#0F0",
        "line-width": 2,
      },
    });

    return () => {
      map.removeLayer("selected");
      map.removeSource("selected");
    };
  }, [selected]);

  useEffect(() => {
    if (!selected.length) setPopups(null);

    const newPopups = selected.map((key) => {
      const features = map.queryRenderedFeatures({
        layers: ["routes"],
        filter: ["==", ["get", "key"], key],
      });

      // i havent figured out why there are multiple features
      const feature = features?.[0];
      if (!feature) return null;

      const line = turf.lineString(feature.geometry.coordinates);
      const center = turf.centroid(line);

      let variants = [];
      if (feature?.properties?.variants) {
        variants = Object.values(JSON.parse(feature.properties.variants));
      }

      return {
        longitude: center.geometry.coordinates[0],
        latitude: center.geometry.coordinates[1],
        variants,
      };
    });

    // console.log(newPopups);

    setPopups(newPopups);
  }, [selected]);



  // return selected.map((key) => {

  //  const features = map.queryRenderedFeatures({
  //     layers: ["routes"],
  //     filter: ["==", ["get", "key"], key],
  //   });

  //   // i havent figured out why there are multiple features
  //   const feature = features?.[0]
  //   if (!feature) return null;

  //   // console.log(feature)

  //   // try {
  //   //   console.log(JSON.parse(feature.properties.variants))
  //   // } catch (e) {
  //   //   console.error(e)
  //   // }

  //   // console.log(turf)

  //   // console.log(turf.lineString)
  //   // const line = turf.lineString(feature.geometry.coordinates);
  //   // const center = turf.centroid(line);
  //   // const [lat, lng] = center.geometry.coordinates;



  return (
    <MapGL
      ref={(ref) => {
        if (ref && !map) setMap(ref.getMap());
      }}
      initialViewState={INITIAL_VIEW_STATE}
      mapStyle={mapStyle && mapStyle.toJS()}
      styleDiffing
      style={{ width: "100%", height: "100%" }}
      mapboxAccessToken={MAPBOX_TOKEN}
      onClick={handleClick}
    >
      {Object.values(airportPins)}
      {pins}
      {popups && popups.map((p, i) => <LinePopup key={i} {...p} />)}
    </MapGL>
  );
};

export default Map;