import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  createContext,
  useContext,
} from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
// import { useSetRecoilState } from "recoil";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

import { api } from "../api.js";
import { getAllPoints, getBoundingBox } from "../workbench/utils.js";

import {
  eventState,
  partiesState,
  scenariosState,
  routesState,
  filterState,
  mapState,
  loadingState,
  airlinesState,
  airportsState,
} from "../atoms.js";
import classNames from "classnames";

import ScenarioMenu from "../workbench/ScenarioMenu.js";
import PartyMenu from "../workbench/PartyMenu.js";
import Controls from "../workbench/Controls.js";
import Map from "../workbench/Map.js";


const Airport = ({ airport }) => (
  <div className="airport">
    <h4>{airport.IATA}</h4>
  </div>
);

const Parties = () => {
  const map = useAtomValue(mapState);
  const [{ selected, items }, setParties] = useAtom(partiesState);

  const go = useCallback(
    (id) => {
      map.flyTo({
        center: items.find((party) => party.id === id).point,
        zoom: 3,
        speed: 1.5,
      });
    },
    [map, items]
  );

  const handleClick = useCallback(
    (e) => {
      const find = parseInt(e.target.closest(".party").dataset.id);
      const id = items.find((party) => party.id === find).id;
      setParties((prev) => ({ ...prev, selected: id }));
      go(id);
    },
    [items]
  );

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      const editing = parseInt(e.target.closest(".party").dataset.id);
      if (editing) {
        setParties((prev) => ({
          ...prev,
          editing,
          selected: editing,
        }));
      }
    },
    [items]
  );

  return (
    <div className="parties">
      <header>parties</header>

      {items.map(({ id, name, airport, color }, i) => (
        <div
          key={i}
          data-id={id}
          className={classNames({
            item: true,
            party: true,
            selected: selected === id,
          })}
          style={{ borderLeftColor: color || "black" }}
          onClick={handleClick}
        >
          <header>
            {name}
            <div className="controls">
              <div className="control edit" onClick={handleEdit}>
                <FaPencilAlt />
              </div>
            </div>
          </header>
          {airport && <Airport airport={airport} />}
        </div>
      ))}

      <div className="add">+</div>
    </div>
  );
};

const Scenarios = () => {
  const map = useAtomValue(mapState);
  const [{ selected, items }, setScenarios] = useAtom(scenariosState);

  const setSelected = useCallback(
    (id) => setScenarios((prev) => ({ ...prev, selected: id })),
    [map, items]
  );

  const handleClick = useCallback(
    (e) => {
      const find = parseInt(e.target.closest(".scenario").dataset.id);
      setSelected(items.find((scenario) => scenario.id === find).id);
    },
    [items]
  );

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      const editing = parseInt(e.target.closest(".scenario").dataset.id);
      if (editing) {
        setScenarios((prev) => ({
          ...prev,
          editing,
          selected: editing,
        }));
      }
    },
    [items]
  );

  return (
    <div className="scenarios">
      <header>scenarios</header>

      {items.map(({ id, name, airport }, i) => (
        <div
          key={i}
          data-id={id}
          className={classNames({
            item: true,
            scenario: true,
            selected: selected === id,
          })}
          onClick={handleClick}
        >
          <header>
            {name}
            <div className="controls">
              <div className="control edit" onClick={handleEdit}>
                <FaPencilAlt />
              </div>
            </div>
          </header>
          {airport && <Airport airport={airport} />}
        </div>
      ))}

      <div className="add">+</div>
    </div>
  );
};


const Event = () => {
  const map = useAtomValue(mapState);
  const [event, setEvent] = useAtom(eventState);
  const [parties, setParties] = useAtom(partiesState);
  const [scenarios, setScenarios] = useAtom(scenariosState);

  useEffect(() => {
    if (!map) return;

    api
      .get("/events/1")
      .then(({ data }) => {
        const { id, name, parties, scenarios } = data;

        parties.sort((a, b) => a.id > b.id);
        scenarios.sort((a, b) => a.id > b.id);

        setEvent({
          id,
          name,
          editing: false,
        });
        setParties({
          selected: null,
          editing: null,
          // items: parties,
          // but ordered by created_at
          items: parties,
        });
        setScenarios({
          selected: null,
          editing: null,
          items: scenarios,
        });

        const points = getAllPoints(parties, scenarios);
        if (points.length) {
          map.fitBounds(getBoundingBox(points), {
            padding: 50,
          });
        } else {
          map.flyTo({
            center: {
              // center of USA
              latitude: 37.8,
              longitude: -96,
            },
            zoom: 3,
          });
        }
      })
      .catch(console.error);
  }, [map]);

  return (
    <EventEditor>
      <header>{event.name}</header>
      <Parties />
      {parties.editing && <PartyMenu />}
      <Scenarios />
      {scenarios.editing && <ScenarioMenu />}
      <Map />
      <Controls />
    </EventEditor>
  );
};

const EventEditor = styled.div`
  background: #eee;
  display: grid;
  grid-template-rows: 1fr 10fr;
  grid-template-columns: 1fr 1fr 8fr 1fr;
  gap: 0.5rem;
  position: relative;

  > header {
    grid-column: 1 / -1;
    background: #333;
    color: white;
    padding: 1rem;
    text-align: center;
  }

  .parties {
    grid-column: 1;
    grid-row: 2;

    > header {
      border-bottom-color: yellow;
    }

    .party {
      &.selected,
      &:hover {
        border-left-color: yellow;
      }
    }
  }

  .scenarios {
    grid-column: 2;
    grid-row: 2;

    > header {
      border-bottom-color: red;
    }

    .scenario {
      &.selected,
      &:hover {
        border-left-color: red;
      }
    }
  }

  > .menu {
    grid-row: 2;

    width: 100%;
    max-width: 300px;

    &,
    form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .name {
        font-size: 1.2rem;
        font-weight: bold;
        // text-align: center;
      }
    }

    background: rgba(255, 0, 0, 0.5);

    // whatever gap is
    margin-left: -0.5rem;
    padding: 10px;

    position: absolute;

    background: #fff;
    border: solid 1px black;

    label {
      font-size: 0.8rem;
    }

    &.party {
      z-index: 10;
      grid-column: 2 / span 2;
    }

    &.scenario {
      z-index: 5;
      grid-column: 3;
      // background: red;
    }

    input,
    select {
      width: 100%;
    }

    .controls {
      display: flex;
      gap: 10px;
      flex-direction: row;
      justify-content: space-between;

      fieldset {
        border: none;
        padding: 0;
        margin: 0;

        legend {
          font-size: 0.8rem;
          font-weight: bold;
        }

        label {
          font-size: 0.8rem;
        }

        input {
          width: 100%;
        }

        .controls {
          display: flex;
          gap: 10px;
          flex-direction: row;
          justify-content: space-around;
        }
      }

      button:first-child {
        margin-left: auto;
      }
    }
  }

  .item {
    padding: 0.5rem;
    cursor: pointer;
    transition: background 0.2s;
    cursor: pointer;
    opacity: 0.5;

    border-left: 0.5rem solid black;

    &.selected,
    &:hover {
      background: #ddd;
      opacity: 1;
    }

    > header {
      margin: 0;
      font-size: 16px;
    }
  }

  .parties,
  .scenarios,
  > .controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > header {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    .add {
      text-align: center;
      padding: 0.3rem;
      cursor: pointer;
      box-shadow: 0rem 0rem 0rem rgba(0, 0, 0, 0);
      transition: box-shadow 0.2s;
      color: white;

      &:hover {
        box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.5);
      }
    }
  }

  .map {
    grid-column: 3;
    grid-row: 2;
  }

  .marker {
    border: solid 1px transparent;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;

    &.scenario {
      opacity: 0.3;
      background-color: red;
    }

    &.selected {
      border-color: black;
      opacity: 1;
    }
  }

  > .controls {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;

    .filters {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .filter {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        small {
          display: none;
          font-size: 0.7rem;
          margin: 0.5rem 0;
        }
      }
    }
  }
`;

export default Event;
