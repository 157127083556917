import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAtom, useAtomValue } from "jotai";

import { api } from "../api";
import { eventState, scenariosState } from "../atoms";
import { useAirportChoices, AIRPORT_DEFAULT } from "./PartyMenu.js";

const ScenarioMenu = () => {
  const event = useAtom(eventState);
  const [scenarios, setScenarios] = useAtom(scenariosState);
  const scenario = useMemo(
    () => scenarios.items.find((s) => s.id === scenarios.editing),
    [scenarios.editing]
  );

  const [airport, setAirport] = useState(AIRPORT_DEFAULT);
  const [name, setName] = useState("");

  const airportChoices = useAirportChoices(scenario);

  useEffect(() => {
    setAirport(scenario?.airport || AIRPORT_DEFAULT);
    setName(scenario?.name || "");
  }, [scenario]);

  const handleClose = useCallback(() => {
    setScenarios((prev) => ({
      ...prev,
      editing: null,
    }));
  }, [setScenarios]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      name,
    };

    if (airport?.id) {
      payload.airport_id = airport.id;
    }

    api
      .put(
        `/events/${event.id}/scenario/${scenario.id}`,
        new URLSearchParams(payload)
      )
      .then(({ data }) => {
        setScenarios((prev) => ({
          ...prev,
          items: prev.items.map((s) => (s.id === data.id ? data : s)),
          editing: null,
        }));
      });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAirportChange = (e) => {
    const id = parseInt(e.target.value, 10);
    setAirport(airportChoices.find((a) => a.id === id));
  };

  const handleDrivingChange = (e) => {
    const checked = e.target.checked;
    console.log(checked);
    if (airport.id !== AIRPORT_DEFAULT.id) {
      // setAirport(AIRPORT_DEFAULT);
      console.log("set airport to default");
    }
  };

  return (
    <div className="menu scenario">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">name</label>
          <input
            id="name"
            className="name"
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
          />
        </div>

        <div>
          <label htmlFor="airport">airport</label>
          <select
            id="airport"
            name="airport"
            value={airport?.id}
            onChange={handleAirportChange}
          >
            <option value="">no one is flying</option>
            {airportChoices.map((a) => (
              <option key={a.id} value={a.id}>
                ({a.IATA}) {a.name}
              </option>
            ))}
          </select>
        </div>

        <div className="controls">
          <button type="submit">Save</button>
          <button onClick={handleClose}>Close</button>
        </div>
      </form>
    </div>
  );
};

export default ScenarioMenu;
