// import { atom } from "recoil";

import { atom, useAtom } from "jotai";

export const orders = {
  recent: "Recently Mentioned",
  score: "Scored",
};

export const appState = atom({
  connected: false,
  title: document.title,
});

export const userState = atom({
  id: undefined,
});

export const companyFormState = atom({
  timespan: "1w",
  order: "recent",
  page: 1,
});

export const eventState = atom({
  editing: false,
  id: null,
  name: null,
});

export const loadingState = atom(false);

export const partiesState = atom({
  selected: null, // id of the selected party
  editing: null, // id of the party being edited
  items: [
    // {
    //   id: 1,
    //   name: "The Green Monkeys",
    //   people: ["Dan", "Em", "Des"],
    //   location: {
    //     address: "3727 Hyde Park Drive, Roanoke, VA 24018",
    //     point: null,
    //     airport: {
    //       id: 6788,
    //       name: "Roanoke-Blacksburg Regional Airport",
    //       IATA: "ROA",
    //       point: [37.3255, -79.9754],
    //     },
    //   },
    // },
    // {
    //   id: 2,
    //   name: "The Purple Parrots",
    //   people: ["Eléonore", "Andrew"],
    //   location: {
    //     address: null,
    //     point: [26.715342, -80.053375], // west palm beach
    //     airport: {
    //       id: 5966,
    //       name: "Palm Beach International Airport",
    //       IATA: "PBI",
    //       point: [26.6832, -80.0956],
    //     },
    //   },
    // },
  ],
});

export const scenariosState = atom({
  selected: null, // id of the selected scenario
  editing: null, // id of the scenario being edited
  items: [
    // {
    //   id: 1,
    //   name: "Vail",
    //   location: {
    //     address: "Vail, CO",
    //     point: null,
    //     airport: {
    //       id: 2044,
    //       name: "Eagle County Regional Airport",
    //       IATA: "EGE",
    //       point: [39.6426, -106.9176],
    //     },
    //   },
    //   date: "2021-08-01",
    // },
    // {
    //   id: 2,
    //   name: "Montana",
    //   location: {
    //     address: null,
    //     point: [46.8796822, -110.3625658], // center of montana
    //     airport: {
    //       id: 725,
    //       name: "Billings Logan International Airport",
    //       IATA: "BIL",
    //       point: [45.8033, -108.5379],
    //     }
    //   },
    //   date: "2021-08-01",
    // },
  ]
});

export const routesState = atom({
  // from roanoke to vail
  // <partyAirportId>: { // Roanoke
  //    <scenario1AirportId>: [ // Vail
  //      { id: 1, name: "Washington Dulles International Airport", IATA: "IAD", point: [38.9531, -77.4565] },
  //      { id: 2, name: "Denver International Airport", IATA: "DEN", point: [39.8561, -104.6737] },
  //    ],
  // }
});

export const filterState = atom({
  airlineType: "all",
  distanceMultiplier: 2.0,
});

export const airlinesState = atom({
  items: [
    // {
    //   id: 1,
    //   name: "United Airlines",
    //   IATA: "UA",
    //   ICAO: "UAL",
    //   callsign: "UNITED",
    // },
    // {
    //   id: 2,
    //   name: "American Airlines",
    //   IATA: "AA",
    //   ICAO: "AAL",
    //   callsign: "AMERICAN",
    // },
  ],
});

export const airportsState = atom({
  items: [
    // {
    //   id: 1,
    //   name: "Washington Dulles International Airport",
    //   IATA: "IAD",
    //   point: [38.9531, -77.4565],
    // },
    // {
    //   id: 2,
    //   name: "Denver International Airport",
    //   IATA: "DEN",
    //   point: [39.8561, -104.6737],
    // },
  ],
});

// https://docs.mapbox.com/mapbox-gl-js/api/map/
export const mapState = atom(null);
