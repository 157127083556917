import React, { useCallback, useEffect, useMemo, useState } from "react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

import { api } from "../api";
import { eventState, partiesState } from "../atoms";

export const useAirportChoices = (item) => {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    api
      .get(`/events/airports`, {
        params: new URLSearchParams({
          latitude: item.point[1],
          longitude: item.point[0],
        }),
      })
      .then(({ data }) => setChoices(data));
  }, [item]);

  return choices;
};

export const AIRPORT_DEFAULT = {
  id: "",
};

const PartyMenu = () => {
  const event = useAtom(eventState);
  const [parties, setParties] = useAtom(partiesState);

  const party = useMemo(
    () => parties.items.find((p) => p.id === parties.editing),
    [parties.editing]
  );

  const [airport, setAirport] = useState(AIRPORT_DEFAULT);
  const [name, setName] = useState("");

  const airportChoices = useAirportChoices(party);

  useEffect(() => {
    setAirport(party?.airport || AIRPORT_DEFAULT);
    setName(party?.name || "");
  }, [party]);

  const handleClose = useCallback(() => {
    setParties((prev) => ({
      ...prev,
      editing: null,
    }));
  }, [setParties]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      name,
    };

    if (airport?.id) {
      payload.airport_id = airport.id;
    }

    api
      .put(
        `/events/${event.id}/party/${party.id}`,
        new URLSearchParams(payload)
      )
      .then(({ data }) => {
        setParties((prev) => ({
          ...prev,
          items: prev.items.map((item) => (item.id === party.id ? data : item)),
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleNameChange = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [setName]
  );

  const handleAirportChange = useCallback(
    (e) => {
      const id = parseInt(e.target.value, 10);
      setAirport(airportChoices.find((a) => a.id === id));
    },
    [setAirport, airportChoices]
  );

  return (
    <div className="menu party">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">name</label>
          <input
            id="name"
            className="name"
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
          />
        </div>

        <div>
          <label htmlFor="airport">airport</label>
          <select
            id="airport"
            name="airport"
            value={airport?.id}
            onChange={handleAirportChange}
          >
            <option value="">is driving</option>
            {airportChoices.map((a) => (
              <option key={a.id} value={a.id}>
                ({a.IATA}) {a.name}
              </option>
            ))}
          </select>
        </div>

        <div className="controls">
          <button type="submit">Save</button>
          <button onClick={handleClose}>Close</button>
        </div>
      </form>
    </div>
  );
};

export default PartyMenu;
