import classnames from "classnames";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Link, NavLink, Outlet } from "react-router-dom";
import { appState } from "../atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";

export const Shell = () => (
  <Stage>
    {/* <Menu /> */}
    <TopBar />
    <Outlet />
  </Stage>
);
const Stage = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 40px auto;
  height: 100vh;
`;

export const Title = ({ children }) => {
  return <StyledTitle>{children}</StyledTitle>;
};
const StyledTitle = styled.div`
  font-size: 24px;
`;

const ActiveNavlink = ({ to, children }) => (
  <NavLink to={to}>{children}</NavLink>
);

export const TopBar = () => (
  <StyledTopBar>
    <div className="logo">
      <Link to="/">Midpoint</Link>
    </div>
  </StyledTopBar>
);
const StyledTopBar = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1;

  display: grid;
  grid-template-columns: 100px auto;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    // background: linear-gradient(180deg, #222 100%, #226688 0%);
    border-bottom: solid 1px #222;
    color: #222;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      width: 100%;
      height: 100%;

      color: #222;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .location {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 9px;
    padding-left: 10px;
    background: linear-gradient(180deg, #222 0%, #226688 100%);
    color: #fff;
  }
`;

export const Menu = () => (
  <></>
  // <StyledMenu>
  //   <ActiveNavlink to="/event">Events</ActiveNavlink>
  // </StyledMenu>
);

const StyledMenu = styled.div`
  grid-column: 1;
  grid-row: 2;
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    color: #000000;
    font-size: 9px;
    padding: 8px;
    text-decoration: none;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: linear-gradient(180deg, #00b4d8 0%, #48cae4 100%);
      color: #ffffff;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .sublinks {
    display: flex;
    flex-direction: column;

    a {
      color: #000000;
      font-size: 8px;
      padding: 4px 4px 4px 16px;
      text-decoration: none;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
`;

export const Workbench = ({ children }) => (
  <StyledWorkbench>{children}</StyledWorkbench>
);
const StyledWorkbench = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin: 16px 0 0 16px;
  height: 100%;
  border: solid 1px orange;
`;

export const Content = ({ children, ...rest }) => (
  <StyledContent {...rest}>{children}</StyledContent>
);
const StyledContent = styled.div`
  width: 100%;
  margin-top: 16px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 4px;
      border-bottom: solid 1px #ccc;
    }

    th {
      font-size: 10px;
      color: #666;
      text-align: left;
    }

    td {
      font-size: 12px;
    }
  }

  --color-primary: #3377aa;
`;

export const Skeleton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // dont show this it immediately
  // otherwise you get a content flash
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <StyledSkeleton>
      <Content>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </Content>
    </StyledSkeleton>
  );
};

const StyledSkeleton = styled.div`
  @keyframes shimmer {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  .bar {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;

    &:nth-child(2) {
      animation-delay: 0s;
      animation-duration: 0.8s;
    }
    &:nth-child(4) {
      animation-delay: 0.1s;
      animation-duration: 0.9s;
    }
    &:nth-child(1) {
      animation-delay: 0.2s;
      animation-duration: 1s;
    }
    &:nth-child(5) {
      animation-delay: 0.3s;
      animation-duration: 1.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
      animation-duration: 1.2s;
    }
  }

  ${Title} {
    .bar {
      width: 50%;
      height: 28px;
    }
  }

  ${Content} {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .bar {
      height: 15px;
    }
  }
`;
