import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  createContext,
  useContext,
} from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";


import { api } from "../api.js";
import { getAllPoints, getBoundingBox } from "../workbench/utils.js";

import {
  eventState,
  partiesState,
  scenariosState,
  routesState,
  filterState,
  mapState,
  loadingState,
  airlinesState,
  airportsState,
} from "../atoms.js";
import classNames from "classnames";

import ScenarioMenu from "../workbench/ScenarioMenu.js";
import PartyMenu from "../workbench/PartyMenu.js";
import Controls from "../workbench/Controls.js";
import Map from "../workbench/Map.js";


const Events = () => {
  // const map = useRecoilValue(mapState);
  // const [event, setEvent] = useRecoilState(eventState);
  // const [parties, setParties] = useRecoilState(partiesState);
  // const [scenarios, setScenarios] = useRecoilState(scenariosState);

  // useEffect(() => {
  //   if (!map) return;

  //   api
  //     .get("/events/1")
  //     .then(({ data }) => {
  //       const { id, name, parties, scenarios } = data;

  //       parties.sort((a, b) => a.id > b.id);
  //       scenarios.sort((a, b) => a.id > b.id);

  //       setEvent({
  //         id,
  //         name,
  //         editing: false,
  //       });
  //       setParties({
  //         selected: null,
  //         editing: null,
  //         // items: parties,
  //         // but ordered by created_at
  //         items: parties,
  //       });
  //       setScenarios({
  //         selected: null,
  //         editing: null,
  //         items: scenarios,
  //       });

  //       const points = getAllPoints(parties, scenarios);
  //       if (points.length) {
  //         map.fitBounds(getBoundingBox(points), {
  //           padding: 50,
  //         });
  //       } else {
  //         map.flyTo({
  //           center: {
  //             // center of USA
  //             latitude: 37.8,
  //             longitude: -96,
  //           },
  //           zoom: 3,
  //         });
  //       }
  //     })
  //     .catch(console.error);
  // }, [map]);

  return (
    <EventEditor>
      events
      {/* <header>{event.name}</header>
        <Parties />
        {parties.editing && <PartyMenu />}
        <Scenarios />
        {scenarios.editing && <ScenarioMenu />}
        <Map />
        <Controls /> */}
    </EventEditor>
  );
};

const EventEditor = styled.div`

  `;

export default Events;
